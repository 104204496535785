import { DefaultButton } from "office-ui-fabric-react";
import "office-ui-fabric-react/dist/css/fabric.min.css";
import React from "react";

import {
  ComboBox,
  Fabric,
  IComboBox,
  IComboBoxOption,
  IComboBoxProps,
  mergeStyles,
  PrimaryButton,
  SelectableOptionMenuItemType
} from "office-ui-fabric-react/lib/index";

export default function App(props) {
  console.log(props);
  return (
    <Fabric>
      <span className="ms-fontSize-24">Filing Summary</span>
      <table style={{ padding: 0, paddingBottom: "0.5rem" }}>
        <tbody>
          <thead>
            <tr>
              <th>Project</th>
              <th>Unfiled Emails</th>
            </tr>
          </thead>
          {props.summary.map(e => (
            <tr>
              <td>{e.key}</td>
              <td>{e.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <image width="60" height="20" src="../../../assets/atvero-logo.png" />
        <DefaultButton
          onClick={() => {
            console.log("Closing");
            Office.context.ui.messageParent("Close");
          }}
        >
          OK
        </DefaultButton>
      </div>
    </Fabric>
  );
}
